export const HelpModalSections = [
  {
    title: "Navigation",
    shortcuts: [
      { keys: ["Alt", "H"], action: "Home" },
      { keys: ["Alt", "Backspace"], action: "Back" },
      { keys: ["Alt", "Shift", "N"], action: "Next" },
      { keys: ["Alt", "Meta"], action: "Meta" },
      { keys: ["Alt", "T"], action: "Ticket" },
    ],
  },
  {
    title: "Listing",
    shortcuts: [
      { keys: ["Alt", "Enter"], action: "Launch search with filter" },
      { keys: ["Alt", "F"], action: "Search" },
      { keys: ["Alt", "-"], action: "Reduce listing result" },
      { keys: ["Alt", "+"], action: "Augment listing result" },
      { keys: ["Alt", ">"], action: "Next page" },
      { keys: ["Alt", "<"], action: "Previous page" },
      { keys: ["Alt", "N"], action: "Add/Create New" },
    ],
  },
  {
    title: "Select Dropdown",
    shortcuts: [
      { keys: ["Alt", "Enter"], action: "Search query" },
      { keys: ["Alt", "R"], action: "Remove search filter" },
    ],
  },
  {
    title: "Detail Page",
    shortcuts: [
      { keys: ["Alt", "Shift", "E"], action: "Edit" },
      { keys: ["Alt", "D"], action: "Delete" },
      { keys: ["Alt", "P"], action: "Print template" },
      { keys: ["Alt", "C"], action: "Close Ticket" },
      { keys: ["Alt", "O"], action: "Open Ticket" },
      { keys: ["Alt", "R"], action: "Reply Ticket" },
      { keys: ["Alt", "A"], action: "Assign Ticket" },
      { keys: ["Alt", "I"], action: "Open Issue Modal" },
    ],
  },
  {
    title: "Form Actions",
    shortcuts: [
      { keys: ["Alt", "Enter"], action: "Confirm/Control" },
      { keys: ["Esc"], action: "Cancel" },
    ],
  },
  {
    title: "Help",
    shortcuts: [{ keys: ["Alt", "?"], action: "Help Modal" }],
  },
];
