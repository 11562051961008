"use client";

// React
import { FC, ChangeEvent, useState, useEffect } from "react";

// Next Intl
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";

// Cookie
import cookie from "js-cookie";

// React Icons
import { HiOutlinePaperAirplane, HiOutlineTranslate } from "react-icons/hi";

// Components
import { Button } from "@/components/Button";
import FeedbackForm from "@/components/FeedbackForm";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Tooltip";
import Clock from "@/components/Clock";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import { IFQCN_BUI } from "@/utils/types/common";

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const Footer: FC<Props> = ({ fqcn_bui }) => {
  const [loginForm, setLoginForm] = useState(false);

  const locale = useLocale();

  const t = useTranslations("Footer");

  const handleLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedLocale = e.target.value;
    const currentPathname = window.location.pathname;
    const newPathname = currentPathname.replace(
      /\/(en|fr)\b/,
      `/${selectedLocale}`
    );
    window.location.href = newPathname;
    cookie.set("NEXT_LOCALE", selectedLocale);
  };

  useEffect(() => {
    if (locale) {
      cookie.set("NEXT_LOCALE", locale);
    }
  }, [locale]);

  return (
    <footer className="flex grid grid-cols-2 md:grid md:grid-cols-3 bg-gray-300 dark:bg-gray-800 p-3 border w-full text-gray-500 gap-2 print:hidden z-20">
      <div className="hidden md:block ">{/*empty div for spacing*/}</div>
      <div
        id={getFQCN(fqcn_bui, "footer")}
        className="flex w-60 md:w-auto items-center justify-center flex-col"
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <article className="flex">
                <HiOutlineTranslate
                  id={getFQCN(fqcn_bui, "footer", "traduction-icon")}
                  className="h-6 w-6 mt-1"
                />
                &nbsp;
                <select
                  id={getFQCN(fqcn_bui, "footer", "select-language")}
                  className="rounded-md px-2 py-1 dark:bg-gray-600 border-[0.5px] border-gray-500 dark:text-gray-300 hover:cursor-pointer"
                  onChange={handleLanguageChange}
                  value={locale}
                >
                  <option
                    id={getFQCN(fqcn_bui, "footer", "option-en")}
                    value="en"
                    className="dark:bg-gray-600 dark:text-gray-300"
                  >
                    English
                  </option>
                  <option
                    id={getFQCN(fqcn_bui, "footer", "option-fr")}
                    value="fr"
                    className="dark:bg-gray-600 dark:text-gray-300"
                  >
                    Français
                  </option>
                </select>
              </article>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t("Traduction")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <article>
          <FeedbackForm
            onClose={() => setLoginForm(false)}
            isOpen={loginForm}
          />
          <Button
            id={getFQCN(fqcn_bui, "footer", "report-button")}
            name="problem-button"
            className="bg-transparent text-gray-500 p-0 h-full hover:bg-transparent hover:text-gray-400 duration-300 ease-in-out"
            onClick={() => setLoginForm(true)}
          >
            <HiOutlinePaperAirplane
              id={getFQCN(fqcn_bui, "footer", "probleme-icon")}
              className="h-5 w-5"
            />
            &nbsp;
            {t("Report a problem")}
          </Button>
        </article>

        <article className="flex justify-center items-center ">
          {t("Powered by")}
          <span className="text-grey-600 font-bold pr-1 pl-1">
            <Link href="https://phpcreation.com/" target="_blank">
              PHPCreation Inc.
            </Link>
          </span>
        </article>
      </div>
      <div className="flex items-end justify-center flex-col">
        <Clock />
      </div>
    </footer>
  );
};

export default Footer;
