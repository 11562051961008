// Axios
import axios from "axios";

// Redux
import { createAsyncThunk } from "@reduxjs/toolkit";

// Cookie
import cookie from "js-cookie";

type LoginThunkArgs = {
  username: string;
  password: string;
};

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (args: LoginThunkArgs, { getState, dispatch }) => {
    try {
      const { data } = await axios.post("/api/auth", args);

      const { token, roles } = data;

      localStorage.setItem("username", args.username);
      localStorage.setItem("roles", JSON.stringify(roles));

      cookie.set("roles", JSON.stringify(roles));

      return {
        token,
        user: {
          username: args.username,
          roles,
        },
      };
    } catch (e: any) {
      if (e?.response?.status === 401) {
        throw new Error("Invalid credentials");
      } else {
        throw new Error(e?.response?.statusText);
      }
    }
  }
);
