import { getFQCN } from "@/utils/functions/fqcn";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { HiOutlinePlayCircle } from "react-icons/hi2";
import { IoClose, IoTicketOutline } from "react-icons/io5";
import { LuLayoutDashboard, LuSettings } from "react-icons/lu";

const fqcn_bui = {
  Bundle: "sidebarMenuBundle",
  Unit: "menu",
  Interface: "Listing",
};

export const SidebarMenus = [
  {
    title: "Dashboard",
    icon: (
      <LuLayoutDashboard
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "dashboard-icon")}
      />
    ),
    link: "/dashboard",
  },
  {
    title: "Opened Tickets",
    icon: (
      <IoTicketOutline
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "open-tickets-icon")}
      />
    ),
    link: "/opened-tickets",
  },
  {
    title: "Closed Tickets",
    icon: (
      <IoClose
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "closed-tickets-icon")}
      />
    ),
    link: "/closed-tickets",
  },
  {
    title: "Parameters",
    icon: (
      <LuSettings
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "parameters-icon")}
      />
    ),
    link: "/parameters",
  },
  {
    title: "Sentry",
    icon: (
      <HiOutlineDocumentReport
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "report-icon")}
      />
    ),
    link: `https://phpcreation.sentry.io/issues/?project=${process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID}`,
  },
  {
    title: "Sentry Latest",
    icon: (
      <HiOutlinePlayCircle
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "sentry-latest-icon")}
      />
    ),
    link: `https://phpcreation.sentry.io/issues/?project=${process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID}&statsPeriod=2d`,
  },
];
